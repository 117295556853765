.LLMAssistantWidget {
  font-family: -apple-system,
    BlinkMacSystemFont,
    sans-serif !important;
  font-style: normal !important;
  text-align: center !important;
  line-height: 20px !important;
  box-sizing: unset !important;
}

.rcw-custom-launcher {
  width: 50px !important;
  height: 50px !important;
  left: 126px !important;
  top: 0px !important;

  align-self: flex-end !important;
  border: 0 !important;
  border-color: transparent !important;
  border-radius: 50% !important;
  background: #FF3621 !important;
  box-shadow: 0px 4px 16px rgba(31, 39, 45, 0.12) !important;
}

.rcw-close-launcher {
  padding-top: 6px !important;
}

.rcw-widget-container {
  max-width: 440px !important;
  right: 32px !important;
  bottom: 32px;
  margin: 0px !important;
  position: fixed !important;
}

.rcw-conversation-container .rcw-header {
  background-color: white !important;
  color: black !important;
  padding: 16px 0 16px 0 !important;
  text-align: left !important;
  border-bottom: 1px solid #CDDAE5 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.rcw-conversation-container .rcw-header .rcw-title {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.rcw-conversation-container {
  max-width: 440px !important;
  height: 620px;
  margin-bottom: 16px !important;

  background: #FFFFFF !important;
  /* Light mode/shadowLow */

  box-shadow: 0px 4px 16px rgba(31, 39, 45, 0.12) !important;
  border-radius: 8px !important;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container.rcw-opened {
    height: 686px !important;
  }
}

@media screen and (max-height: 850px) and (max-width: 800px), screen and (max-height: 850px) {
  .rcw-widget-container.rcw-opened {
    height: auto !important;
  }

  .rcw-conversation-container {
    width: 100% !important;
    height: calc(100vh - 210px) !important;
  }

  .rcw-messages-container {
    width: 100% !important;
    height: calc(100vh - 328px) !important;
  }
}

.rcw-disclaimer {
  color: #5d7283;
  font-style: italic;
}

.rcw-loading {
  line-height: 8px !important;
  font-weight: bold !important;
  vertical-align: middle !important;
}

.rcw-loading::after {
  content: ". . ." !important;
  overflow: hidden !important;
  animation: ellipsis-dot 1s infinite .3s !important;
  animation-fill-mode: forwards !important;
  width: 1.25em !important;
}

@keyframes ellipsis-dot {
  50% {
    content: ".";
  }

  75% {
    content: ". .";
  }

  100% {
    content: ". . .";
  }
}

.rcw-message {
  font-weight: 400 !important;
  font-size: 13px !important;
  margin: 16px !important;
}

.rcw-client {
  background: #0E538B !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  max-width: 300px !important;
  margin-right: 8px;
}

.rcw-client .rcw-message-text p {
  color: white !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.rcw-response {
  padding: 12px 16px !important;
  color: #1F272D !important;
  background-color: #F2F5F7 !important;
  max-width: 300px !important;
  border-radius: 8px !important;
}

.rcw-message-text p {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #1F272D !important;
}

.rcw-message-text code {
  white-space: normal !important;
}

.rcw-message-text ol li {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #1F272D !important;
  list-style: decimal !important;
}

.rcw-message-text ol {
  margin-left: 20px !important;
  list-style: decimal !important;
}

.rcw-message-text pre {
  padding: 16px !important;
  gap: 8px !important;

  background: #E4ECF1 !important;
  border-radius: 8px !important;
}

.rcw-message-text pre code {
  white-space: pre-wrap !important;
}

.rcw-rate-limited {
  color: #C82D4C !important;
}

.rcw-sources-title {
  color: #5D7283 !important;
  margin-block-end: 0 !important;
  font-size: 13px !important;
}

.rcw-sources {
  margin-top: 5px !important;
}

.rcw-link {
  color: #2272B4 !important;
  text-decoration-line: none !important;
  font-size: 13px !important;
}

.rcw-feedback:hover {
  cursor: pointer;
}

.rcw-thumbs-up {
  margin-right: 8px !important;
}

.quick-button {
  background: #0E538B !important;
  border-radius: 4px !important;
  border: 0 !important;

  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #FFFFFF !important;

  margin-bottom: 8px !important;
}

.quick-buttons-container {
  padding: 16px !important;
  border-radius: 8px !important;
}

.rcw-question-separator {
  background-color: #CDDAE5 !important;
  width: 332px !important;
  height: 1px !important;
  border: none !important;

  margin-block-start: 0.5em !important;
  margin-block-end: 0.5em !important;
  margin-inline-start: auto !important;
  margin-inline-end: auto !important;
}

.rcw-send-icon {
  height: 18px !important;
  width: 21px !important;
  filter: invert(43%) sepia(23%) saturate(429%) hue-rotate(165deg) brightness(94%) contrast(86%) !important;
}

.rcw-send {
  padding: 0 !important;
  background-color: white !important;
}

.rcw-sender {
  position: fixed !important;
  width: 408px !important;
  bottom: 0 !important;
  padding: 16px !important;
  height: 32px !important;

  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: white !important;
  border-top: 1px solid #CDDAE5 !important;

  box-sizing: unset !important;
}

.rcw-new-message {
  padding: 0px !important;
  height: 20px !important; 
  background-color: white !important;
  font-size: 13px !important;
  border: none !important;
  box-shadow: none !important;
}

.rcw-messages-container {
  padding-top: 0 !important;
  max-height: 502px !important;
  height: auto;
}

.rcw-conversation-container .avatar {
  width: 16px !important;
  height: 16px !important;
  padding-left: 24px !important;
  border-radius: 0 !important;
  box-sizing: unset !important;
}

.rcw-avatar {
  width: 16px !important;
  height: 16px !important;

  padding: 8px;
  margin: 0 8px !important;
  background: #FFF5F7 !important;

  box-sizing: unset !important;
  position: inherit !important;
}

.rcw-close-button {
  display: none !important;
}

.rcw-custom-launcher:hover {
  cursor: pointer;

  box-shadow: 0px 4px 16px rgba(31, 39, 45, 0.12) !important;
}

.rcw-open-text {
  top: 10px;
  right: 80%;
  position: absolute;
  z-index: -1;
  max-width: none;
}
